import create from 'zustand'
import { STATUS } from '../constants';
import { APIS } from '../services/api';

export const useProductStore = create((set,get) => ({
  addProduct: async (payload) => {
    set({ addProductStatus: STATUS.FETCHING })
    const response = await APIS.addProductApi(payload)
    if (response.ok) {
      set({ addProductData: response.data, addProductStatus: STATUS.SUCCESS })
      get().getProducts();
    }
    else {
      set({ addProductStatus: STATUS.FAILED})
    }
  },
  updateProduct: async (payload) => {
    set({ updateProductStatus: STATUS.FETCHING })
    const response = await APIS.updateProductApi(payload)
    if (response.ok) {
      set({ updateProductData: response.data, updateProductStatus: STATUS.SUCCESS })
      get().getProducts();
    }
    else {
      set({ updateProductStatus: STATUS.FAILED})
    }
  },
  getProducts: async (payload) => {
    set({ getProductsStatus: STATUS.FETCHING })
    const response = await APIS.getProductsApi(payload)
    if (response.ok) {
      set({ getProductData: response.data, getProductsStatus: STATUS.SUCCESS })
    }
    else {
      set({ getProductsStatus: STATUS.FAILED})
    }
  },
  getSingleProduct: async (payload) => {
    set({ productStatus: STATUS.FETCHING })
    const response = await APIS.getProductApi(payload)
    if (response.ok) {
      set({ getProduct: response.data, productStatus: STATUS.SUCCESS })
    }
    else {
      set({ productStatus: STATUS.FAILED, loginData: null })
    }
  },
}));