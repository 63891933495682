import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, message, Radio } from "antd";
import { useUserStore } from "../../store/userStore";
import {
  LoadingOutlined,
  PlusOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import upload from "../../Images/upload.jpg";

const AddImage = ({ onSubmit, editData, setIsModalOpen }) => {
  const [form] = Form.useForm();
  const updateUser = useUserStore((s) => s.updateUser);

  const inputRef = useRef();
  const [imageUrl, setImageUrl] = useState();

  const handleInputChange = (e) => {
    if (e.target.files.length === 0) return;
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = (res) => {
      const dataUrl = res.currentTarget?.result;
      setImageUrl(dataUrl);
      console.log("resres", dataUrl, res);
    };
    reader.readAsDataURL(file);
    console.log("file", e.target);
  };

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        title: editData.title,
        url: editData.url,
        priority: editData.priority,
        isActive: true,
      });
    }
  }, [editData, form]);

  const _updateUser = (values) => {
    console.log({ values });
    updateUser({
      id: editData.id,
      ...values,
    });
    setIsModalOpen(false);
  };

  return (
    <Form
      labelCol={{
        span: 7,
      }}
      form={form}
      onFinish={editData ? _updateUser : onSubmit}
      name="register"
      scrollToFirstError
      style={{ margin: "20px", padding: "20px" }}
    >
      <Form.Item
        name="image"
        label="Upload Image"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please upload Image!",
        //   },
        // ]}
      >
        <div style={{ display: "none" }}>
          <input onChange={handleInputChange} ref={inputRef} type="file" />
        </div>

        {/* {imageUrl ? ( */}
        <img
          src={imageUrl ?? upload}
          alt="avatar"
          style={{
            width: "150px",
            height: "150px",
            borderRadius: "400px",
          }}
          onClick={() =>editData?{}: inputRef.current.click()}
        />
        {/* ) : null} */}
        {/* <div
        style={{ position: "absolute", zIndex: 1 }}
        onClick={() => inputRef.current.click()}
      >
        <CameraOutlined />
      </div> */}
      </Form.Item>
      <Form.Item
        name="title"
        label="Title"
        tooltip="Enter your Name"
        rules={[
          {
            required: true,
            message: "Please enter Title!",
            whitespace: true,
            type: "string",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="url"
        label="URL"
        tooltip="Enter your Name"
        rules={[
          {
            required: true,
            message: "Please enter your URL!",
            whitespace: true,
            type: "string",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="priority"
        label="Priority"
        rules={[
          {
            required: true,
            message: "Please input your Priority!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Status"
        name="is_active"
        rules={[
          {
            required: true,
            message: "Please select status!",
            type: "string",
          },
        ]}
      >
        <Radio.Group>
          <Radio.Button value="1">Active</Radio.Button>
          <Radio.Button value="0">Inactive</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddImage;
