import { create } from "apisauce";
import apiMonitor from "./Monitor";

// export const BASE_URL = "http://testapi.risingassociates.co.in/api/v1";
export const BASE_URL = "https://api.onlinebanks.in/api/v1";

export const URIs = {
  LOGIN_USER: "auth/login",
  ADD_USER: "user-master",
  UPDATE_USER: "user-master",
  GET_USERS: "user-master/all",
  ADD_PRODUCT: "product",
  GET_PRODUCTS: "product/all",
  GET_PRODUCT: "product",
  //PIN CODE
  ADD_PINCODE: "pincode",
  ALL_PINCODE: "pincode/all",
  //COMPANY
  ADD_COMPANY: "company",
  LIST_COMPANY: "company/all",
  //BANK
  BANK: "bank",
  LIST_BANK: "bank/all",
  //role
  ROLE: "role-master",
  LIST_ROLE: "role-master/all",
  //vendor
  VENDOR: "domain-master",
  LIST_VENDOR: "domain-master/all",
  //category
  ADD_CATEGORY: "category",
  ALL_CATEGORY: "category/all",
  UPDATE_CATEGORY: "category",
  //html file
  HTML_FILE: "app/create-multiple-html-file",
  SLIDER_IMAGE:'slider-image',
  SLIDER_IMAGES_ALL: 'slider-image/all'
};

let api = create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
  },
  timeout: 15000,
});

api.addMonitor(apiMonitor);

const setAuthorizationHeader = (access_token) =>
  api.setHeader("Authorization", "Bearer " + access_token);

const loginUser = (data) => api.post(URIs.LOGIN_USER, data);
const addUserApi = (data) => api.post(URIs.ADD_USER, data);
const updateUserApi = (data) => api.patch(URIs.UPDATE_USER, data);
const getUsersApi = (data) => api.get(URIs.GET_USERS, data);
//product
const addProductApi = (data) => api.post(URIs.ADD_PRODUCT, data);
const updateProductApi = (data) => api.patch(URIs.ADD_PRODUCT, data);
const getProductsApi = (data) => api.get(URIs.GET_PRODUCTS, data);
const getProductApi = (data) => api.get(URIs.GET_PRODUCT, data);
//pincode
const addPinCode = (data) => api.post(URIs.ADD_PINCODE, data);
const getAllPincode = () => api.get(URIs.ALL_PINCODE, {});
//company
const addCompany = (data) => api.post(URIs.ADD_COMPANY, data);
const updateCompany = (data) => api.patch(URIs.ADD_COMPANY, data);
const getAllCompany = () => api.get(URIs.LIST_COMPANY, {});
//bank
const addBank = (data) => api.post(URIs.BANK, data);
const updateBank = (data) => api.patch(URIs.BANK, data);
const getAllBank = () => api.get(URIs.LIST_BANK, {});
//ROLE
const addRole = (data) => api.post(URIs.ROLE, data);
const updateRole = (data) => api.patch(URIs.ROLE, data);
const getAllRole = () => api.get(URIs.LIST_ROLE, {});
//VENDOR
const addVendor = (data) => api.post(URIs.VENDOR, data);
const updateVendor = (data) => api.patch(URIs.VENDOR, data);
const getAllVendor = () => api.get(URIs.LIST_VENDOR, {});
//CATEGORY
const addCategory = (data) => api.post(URIs.ADD_CATEGORY, data);
const updateCategory = (data) => api.patch(URIs.UPDATE_CATEGORY, data);
const getAllCategory = () => api.get(URIs.ALL_CATEGORY, {});
//HTML
const uploadHtmlFile = (data) => api.post(URIs.HTML_FILE, data);
const sliderImage = (data) => api.post(URIs.SLIDER_IMAGE, data);
const updateSliderImage = (data) => api.patch(URIs.SLIDER_IMAGE, data);
const getSliderImages = (data) => api.get(URIs.SLIDER_IMAGES_ALL, data);

export const APIS = {
  setAuthorizationHeader,
  loginUser,
  addUserApi,
  updateUserApi,
  getUsersApi,
  addProductApi,
  updateProductApi,
  getProductsApi,
  getProductApi,
  addPinCode,
  getAllPincode,
  addCompany,
  updateCompany,
  getAllCompany,
  addBank,
  updateBank,
  getAllBank,
  addRole,
  updateRole,
  getAllRole,
  addVendor,
  updateVendor,
  getAllVendor,
  addCategory,
  getAllCategory,
  updateCategory,
  uploadHtmlFile,
  sliderImage,
  updateSliderImage,
  getSliderImages
};
