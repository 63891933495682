import React, { useEffect, useLayoutEffect } from "react";
import { Button, Card, Form, Input, message, Select } from "antd";
import { useVendorStore } from "../../store/vendorStore";
import UseStatusCheck from "../../lib/UseStatusCheck";
const { Option } = Select;

const AddVendor = ({ onSubmit, editData, setIsModalOpen }) => {
  const [form] = Form.useForm();
  const updateVendor = useVendorStore((s) => s.updateVendor);
  const updateVendorStatus = useVendorStore((s) => s.updateVendorStatus);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        name: editData.first_name,
        vendor_name: editData.name,
        email: editData.email,
        phone: editData.mobile_no,
        address: editData.address ?? null,
        pincode: editData.pincode ?? null,
        inactive_reason: "",
        isActive: true,
      });
    }
  }, [editData, form]);

  const _updateVendor = (values) => {
    console.log({ values });
    const data = {
      id: editData.id,
      name: values.vendor_name,
      user_master_data: {
        first_name: values.name,
        last_name: "",
        email: values.email,
        mobile_no: values.phone,
        id: editData.userMaster.id,
      },
    };
    updateVendor(data);
    setIsModalOpen(false);
  };

  UseStatusCheck({
    status: updateVendorStatus,
    onSuccess: () => {
      messageApi.open({
        type: "success",
        content: "Vendor Updated Successfully",
      });
      form.resetFields();
      setIsModalOpen(false);
    },
    onError: () => {
      messageApi.open({
        type: "error",
        content: "Vendor Update Failed",
      });
      form.resetFields();
      setIsModalOpen(false);
    },
  });

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );
  return (
    <>
      {contextHolder}
      <Card title="Add Vendor">
        <Form
          labelCol={{
            span: 6,
          }}
          form={form}
          name="register"
          onFinish={editData ? _updateVendor : onSubmit}
          initialValues={{
            prefix: "+91",
          }}
          scrollToFirstError
          style={{ margin: 40 }}
        >
          <Form.Item
            name="vendor_name"
            label="Vendor Name"
            tooltip="Enter vendor Name"
            rules={[
              {
                required: true,
                message: "Please enter vendor Name!",
                whitespace: true,
                type: "string",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Admin Name"
            tooltip="Enter user Name"
            rules={[
              {
                required: true,
                message: "Please enter user name!",
                whitespace: true,
                type: "string",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
                max: 10,
              },
              {
                message: "Please Enter Valid Phone number",
                pattern: `^[6-9]{1}[0-9]{9}$`,
              },
            ]}
          >
            <Input style={{ width: "100%" }} addonBefore={prefixSelector} />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Address">
            <Input />
          </Form.Item>
          <Form.Item
            name="pincode"
            label="Pincode"
            messageVariables={"Enter 6 digit pincode"}
            rules={[{ message: "Enter Your Pincode", max: 6 }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AddVendor;
