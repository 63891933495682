import _, { map } from "lodash";
import create from "zustand";
import { STATUS } from "../constants";
import { APIS } from "../services/api";

export const useHtmlStore = create((set, get) => ({
  createHtmlText: async (payload) => {
    set({ htmlStatus: STATUS.FETCHING });
    const apiData = map(payload, (value, key) => {
      return {
        key,
        htmlData: value,
      };
    });
    const response =await APIS.uploadHtmlFile(apiData);
    const data = Object.fromEntries(response?.data?.map((t) => [t.key, t.htmlData]))

    if (response.ok) {
      set({ htmlText: data, htmlStatus: STATUS.SUCCESS });
    } else {
      set({ htmlStatus: STATUS.FAILED });
    }
  },
}));
