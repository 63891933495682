import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";

const AddBank = ({ onSubmit, editData, setIsModalOpen }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editData) {
    }
  }, [editData, form]);

  const _updateBank = (values) => {
    console.log({ values });
    setIsModalOpen(false);
  };

  return (
    <Form
      labelCol={{
        span: 7,
      }}
      form={form}
      onFinish={editData ? _updateBank : onSubmit}
      name="register"
      scrollToFirstError
      style={{ margin: "20px", padding: "20px" }}
    >
      <Form.Item
        name="name"
        label="Name"
        tooltip="Enter Bank Name"
        rules={[
          {
            required: true,
            message: "Please enter your bank Name!",
            whitespace: true,
            type: "string",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="code"
        label="Code"
        tooltip="Enter Bank Code"
        rules={[
          {
            required: true,
            message: "Please enter your bank code",
            whitespace: true,
            type: "string",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddBank;
