import create from "zustand";
import { STATUS } from "../constants";
import { APIS } from "../services/api";

export const useVendorStore = create((set, get) => ({
  addVendor: async (payload) => {
    set({ addVendorStatus: STATUS.FETCHING });
    const response = await APIS.addVendor(payload);
    if (response.ok) {
      set({ addVendorStatus: STATUS.SUCCESS });
      get().getVendors();
    } else {
      set({ addVendorStatus: STATUS.FAILED });
    }
  },
  updateVendor: async (payload) => {
    set({ updateVendorStatus: STATUS.FETCHING });
    const response = await APIS.updateVendor(payload);
    if (response.ok) {
      set({ updateVendorStatus: STATUS.SUCCESS });
      get().getVendors();
    } else {
      set({ updateVendorStatus: STATUS.FAILED });
    }
  },
  getVendors: async (payload) => {
    set({ status: STATUS.FETCHING });
    const response = await APIS.getAllVendor(payload);
    if (response.ok) {
      set({ vendorList: response.data?.results, status: STATUS.SUCCESS });
    } else {
      set({ status: STATUS.FAILED, vendorList: [] });
    }
  },
}));
