import { Card, Descriptions, Image } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCategoryStore } from "../../store/categoryStore";
import { useProductStore } from "../../store/productStore";
import { Collapse } from "antd";
const { Panel } = Collapse;

export default ({}) => {
  const { state } = useLocation();
  const getSingleProduct = useProductStore((state) => state.getSingleProduct);
  const getProduct = useProductStore((state) => state.getProduct);
  const allCategories = useCategoryStore((s) => s.allCategories);

  useEffect(() => {
    getSingleProduct({ id: state.id });
  }, [state]);
  console.log({ getProduct, allCategories });

  const category =
    allCategories &&
    allCategories.length &&
    allCategories.find((cat) => cat.id === getProduct?.product_category_id);

  console.log({ category });
  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <Card title="Product Details" style={{ alignItems: "center" }}>
      {getProduct && (
        <Descriptions>
          <Descriptions.Item label="Name">{getProduct?.name}</Descriptions.Item>
          <Descriptions.Item label="Status">
            {getProduct?.is_active === 1 ? "Active" : "InActive"}
          </Descriptions.Item>
          <Descriptions.Item label="Category">
            {category?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Company">
            {getProduct?.company}
          </Descriptions.Item>
          <Descriptions.Item label="Pincode">
            {getProduct?.pincode}
          </Descriptions.Item>
          <Descriptions.Item label="Url">
            <a target={'_blank'} href={getProduct.url}>{getProduct.url}</a>
          </Descriptions.Item>
        </Descriptions>
      )}
      <Card title="Description" style={{ marginTop: "40px" }}>
        <span>{getProduct?.description}</span>
      </Card>
      <Card title="Product Criteria" style={{ marginTop: "40px" }}>
        {getProduct && getProduct.product_criteria_data && (
          <Descriptions>
            <Descriptions.Item label="Minimum Salary">
              {getProduct.product_criteria_data.minimum_salary}
            </Descriptions.Item>
            <Descriptions.Item label="Maximum Salary">
              {getProduct.product_criteria_data.maximum_salary}
            </Descriptions.Item>
            <Descriptions.Item label="Cibil Score">
              {getProduct.product_criteria_data.cibil}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Card>
      <Card title="Features" style={{ marginTop: "40px" }}>
        {/* <span>{getProduct?.description}</span> */}
      </Card>
    </Card>
  );
};
