import create from 'zustand'
import { STATUS } from '../constants';
import { APIS } from '../services/api';

export const useCompanyStore = create((set,get) => ({
  addCompany: async (payload) => {
    set({ addCompanyStatus: STATUS.FETCHING })
    const response = await APIS.addCompany(payload)
    if (response.ok) {
      set({ addCompanyData: response.data, addCompanyStatus: STATUS.SUCCESS });
      get().getAllCompany();
    }
    else {
      set({ addCompanyStatus: STATUS.FAILED})
    }
  },
  updateCompany: async (payload) => {
    set({ updateStatus: STATUS.FETCHING })
    const response = await APIS.updateCompany(payload)
    if (response.ok) {
      set({ updateStatus: STATUS.SUCCESS });
      get().getAllCompany();
    }
    else {
      set({ updateStatus: STATUS.FAILED})
    }
  },
  getAllCompany: async () => {
    set({ status: STATUS.FETCHING })
    const response = await APIS.getAllCompany()
    if (response.ok) {
      set({ allCompany: response.data?.results, status: STATUS.SUCCESS });
    }
    else {
      set({ status: STATUS.FAILED})
    }
  },
}));