import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Drawer,
  Dropdown,
  Form,
  Input,
  message,
  Space,
  Table,
} from "antd";
import { useProductStore } from "../../store/productStore";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import UseStatusCheck from "../../lib/UseStatusCheck";
import { STATUS } from "../../constants";

const ProductList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [activeProduct, setActiveProduct] = useState();
  const updateProduct = useProductStore((s) => s.updateProduct);
  const { getProducts, getProductData } = useProductStore((state) => ({
    getProducts: state.getProducts,
    getProductData: state.getProductData,
  }));

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const edit = (record) => {
    navigate(`/${ROUTES.ADD_PRODUCT}?backurl=${ROUTES.LIST_PRODUCT}`, {
      replace: true,
      state: record,
    });
  };

  const handleClickMenu = (e, data) => {
    console.log({ e });
    if (e.key === "1") {
      edit(data);
    }
    if (e.key === "2") {
      navigate(`/${ROUTES.PRODUCT_DETAILS}`, { state: data });
    }
    if (e.key === "3-1") {
      updateProduct({ id: data.id, is_active: "1" });
    }
    if (e.key === "3-2") {
      updateProduct({ id: data.id, is_active: "0" });
    }
    if (e.key === "5") {
      setActiveProduct(data);
      showDrawer();
    }
  };

  const items = [
    {
      key: "1",
      label: <p>Edit</p>,
    },
    {
      key: "2",
      label: <p>Status</p>,
      children: [
        {
          key: "3-1",
          label: "Active",
        },
        {
          key: "3-2",
          label: "In Active",
        },
      ],
    },
    {
      key: "4",
      label: <p>Show Details</p>,
    },
    {
      key: "5",
      label: <p>Update URL</p>,
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sortDirections: ["descend"],
      filterSearch: true,
      onFilter: (value, record) => record.name.includes(value),
    },
    {
      title: "Url",
      dataIndex: "url",
      render: (text) => <a>{text}</a>
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <Dropdown
            menu={{ items, onClick: (e) => handleClickMenu(e, record) }}
            placement="bottom"
          >
            <Button>Action</Button>
          </Dropdown>
        );
      },
    },
  ];

  const onChange = (pagination, sorter, extra) => {
    console.log("params", pagination, sorter, extra);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Card title="List Products">
      <Table
        bordered
        sticky
        columns={columns}
        dataSource={getProductData?.results}
        onChange={onChange}
      />
      <Drawer
        title="Create a new Vendor"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <EditUrl
          setIsModalOpen={setOpen}
          id={activeProduct?.id}
          name={activeProduct?.name}
          url={activeProduct?.url}
        />
      </Drawer>
    </Card>
  );
};
const EditUrl = ({ id, name, url, setIsModalOpen }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const updateProduct = useProductStore((s) => s.updateProduct);
  const updateProductStatus = useProductStore((s) => s.updateProductStatus);
  const loading = updateProductStatus === STATUS.FETCHING;
  useEffect(() => {
    form.setFieldsValue({
      name: name,
      url: url,
    });
  }, [name, url, id, form]);

  const _updateProduct = (values) => {
    updateProduct({
      id: id,
      ...values,
    });
  };

  UseStatusCheck({
    status: updateProductStatus,
    onSuccess: () => {
      setIsModalOpen(false);
      messageApi.open({
        type: "success",
        content: "Product Added Successfully",
      });
    },
  });

  return (
    <>
      {contextHolder}
      <Card title="Update Product URL">
        <Form
          labelCol={{
            span: 6,
          }}
          form={form}
          name="Update"
          onFinish={_updateProduct}
          initialValues={{
            prefix: "+91",
          }}
          scrollToFirstError
          style={{ margin: 40 }}
        >
          <Form.Item
            name="name"
            label="Product Name"
            tooltip="Enter Product Name"
            rules={[
              {
                required: true,
                message: "Please enter Product Name!",
                whitespace: true,
                type: "string",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="url"
            label="Product URL"
            tooltip="Enter Product URL"
            rules={[
              {
                required: true,
                message: "Please enter product URL!",
                whitespace: true,
                type: "string",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default ProductList;
