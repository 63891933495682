import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
const HtmlEditor = ({ setHtmlDataState }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const _onEditorStateChange = (e) => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setEditorState(e);
    setHtmlDataState(html);
  };
  return (
    <>
      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        editorState={editorState}
        onEditorStateChange={_onEditorStateChange}
        wrapperStyle={{
          width: "700px",
          border: "1px solid #DEE0E2",
          borderRadius: 10,
        }}
        editorStyle={{paddingInline:10}}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
      />
    </>
  );
};

export default HtmlEditor;
