import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  Popconfirm,
  Popover,
  Table,
  Typography,
} from "antd";
import _ from "lodash";
import { useUserStore } from "../../store/userStore";
import AddUser from "./AddUser";

const UserList = ({}) => {
  const [form] = Form.useForm();
  const addUser = useUserStore((state) => state.addUser);
  const { getUsers, getUserData } = useUserStore((state) => ({
    getUsers: state.getUsers,
    getUserData: state.getUserData,
  }));
  const [data, setData] = useState();
  const [editData, setEditData] = useState();
  const [deleted, setDeleted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);
  console.log({ getUserData });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const _handleSubmit = (values) => {
    addUser(values);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const listData = _.map(getUserData, (data) => {
    return {
      key: data.id,
      name: data.first_name + " " + data.last_name,
      email: data.email,
      mobile_no: data.mobile_no,
    };
  });

  const edit = (record) => {
    setIsModalOpen(true);
    setEditData(record);
  };
  
  const _onDelete = (record) => {
    setData(_.filter(data, (d) => d.key !== record.key));
  };
  const hide = () => {
    setDeleted(false);
  };
  const handleOpenChange = (record) => {
    setDeleted(record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
      render: (text, record) => (
        <span>
          {record.first_name} {record.last_name}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Contact",
      dataIndex: "mobile_no",
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <>
            <Typography.Link
              onClick={() => edit(record)}
              style={{ marginRight: "30px " }}
            >
              Edit
            </Typography.Link>
            <Popover
              content={
                <span>
                  <Typography.Link
                    onClick={() => _onDelete(record)}
                    style={{ marginRight: "8px" }}
                  >
                    Save
                  </Typography.Link>
                  <Popconfirm title="Sure to cancel?" onConfirm={hide}>
                    <a>Cancel</a>
                  </Popconfirm>
                </span>
              }
              title="Are you want to delete?"
              trigger="click"
              open={deleted}
              onOpenChange={() => handleOpenChange(record)}
            >
              <Typography.Link onClick={() => setDeleted(record)}>
                Delete
              </Typography.Link>
            </Popover>
          </>
        );
      },
    },
  ];

  const onChange = (pagination, sorter, extra) => {
    console.log("params", pagination, sorter, extra);
  };

  return (
    <Card
      title="List Users"
      extra={
        <Button type="primary" onClick={showModal}>
          Add New User
        </Button>
      }
    >
      <Modal
        title="Add User"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <AddUser
          onSubmit={_handleSubmit}
          editData={editData}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
      <Table
        bordered
        columns={columns}
        dataSource={getUserData}
        onChange={onChange}
      />
    </Card>
  );
};

export default UserList;
