import create from "zustand";
import { LOCALSTORAGE_KEYS, STATUS } from "../constants";
import { APIS } from "../services/api";

export const useLoginStore = create((set, get) => ({
  loginStatus: STATUS.NOT_STARTED,
  loginUser: async (payload) => {
    set({ loginStatus: STATUS.FETCHING });
    const response = await APIS.loginUser(payload);
    if (response.ok) {
      const data = response.data;
      localStorage.setItem(LOCALSTORAGE_KEYS.LOGIN_TOKEN, data.token);
      localStorage.setItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN, data.refreshToken);
      localStorage.setItem(LOCALSTORAGE_KEYS.USER, JSON.stringify(data.user));
      set({
        loginData: response.data,
        user: data.user,
        loginStatus: STATUS.SUCCESS,
      });
    } else {
      localStorage.removeItem(LOCALSTORAGE_KEYS.LOGIN_TOKEN);
      set({
        loginStatus: STATUS.FAILED,
        loginData: null,
        errormsg: response.data?.message,
      });
    }
  },
  userProfile: async (payload) => {
    set({ status: STATUS.FETCHING });
    const response = await APIS.loginUser(payload);
    if (response.ok) {
      set({ user: response.data, status: STATUS.SUCCESS });
    } else {
      set({
        status: STATUS.FAILED,
        errormsg: response.data?.message,
      });
    }
  },
}));
