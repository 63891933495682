
import create from "zustand";
import { STATUS } from "../constants";
import { APIS } from "../services/api";

export const useCategoryStore = create((set, get) => ({
  addCategory: async (payload) => {
    set({ addCategoryStatus: STATUS.FETCHING });
    const response = await APIS.addCategory(payload);
    if (response.ok) {
      set({addedCategoryData:response.data,addCategoryStatus: STATUS.SUCCESS });
      get().getCategories();
    } else {
      set({ addCategoryStatus: STATUS.FAILED });
    }
  },
  updateCategory: async (payload) => {
    set({ updateCategoryStatus: STATUS.FETCHING });
    const response = await APIS.updateCategory(payload);
    if (response.ok) {
      set({updatedCategoryData:response.data, updateCategoryStatus: STATUS.SUCCESS });
      get().getCategories();
    } else {
      set({ updateCategoryStatus: STATUS.FAILED });
    }
  },
  getCategories: async () => {
    set({ categoryStatus: STATUS.FETCHING });
    const response = await APIS.getAllCategory();
    if (response.ok) {
      set({ allCategories: response.data, categoryStatus: STATUS.SUCCESS });
    } else {
      set({ categoryStatus: STATUS.FAILED });
    }
  },
}));
