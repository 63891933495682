import { Avatar, Button, Card, Col, Layout, Menu, Row, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import {
  PieChartOutlined,
  UserOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../contexts/AppContext";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const superAdminItems = [
  getItem("Dashboard", ROUTES.DASHBOARD, <PieChartOutlined />),
  getItem("Vendor", ROUTES.LIST_VENDORS, <UserOutlined />),
  getItem("User", ROUTES.USERS, <UserOutlined />),
  getItem("Company", ROUTES.COMPANY, <UserOutlined />),
  getItem("Product", "", <MailOutlined />, [
    getItem("List", ROUTES.LIST_PRODUCT),
    getItem("Add", ROUTES.ADD_PRODUCT),
    getItem("Category", ROUTES.CATEGORY),
  ]),
  getItem("Role", ROUTES.ROLE, <UserOutlined />),
  getItem("Bank", ROUTES.BANK, <UserOutlined />),
  getItem("Slider", ROUTES.SLIDER, <UserOutlined />),
];

const vendorItems = [
  getItem("Dashboard", ROUTES.DASHBOARD, <PieChartOutlined />),
  getItem("Product", "", <MailOutlined />, [
    getItem("List", ROUTES.LIST_PRODUCT),
    getItem("Add", ROUTES.ADD_PRODUCT),
  ]),
];

const { Title } = Typography;
const { Header, Content, Footer, Sider } = Layout;

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { user, logout } = useContext(AppContext);
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (user?.username === "sysadmin") {
      setItems(superAdminItems);
    } else {
      setItems(vendorItems);
    }
  }, [user]);

  const navigate = useNavigate();
  return (
    <div>
      <Layout style={{ height: "200vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div
            style={{
              padding: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              style={{
                backgroundColor: "#f56a00",
                verticalAlign: "middle",
              }}
              size="large"
              gap={0}
            >
              {"R"}
            </Avatar>
            <div style={{ color: "white" }}>
              <Title level={4} style={{ color: "white" }}>
                {user?.first_name}
              </Title>
            </div>
          </div>
          <Menu
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            // selectedKeys={[ROUTES.USERS]}
            theme="dark"
            items={items}
            onSelect={({ key }) => navigate(key)}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: "white",
            }}
          >
            <Row style={{ alignItems: "center", paddingLeft: 10 }}>
              <Col span={8}>
                <Title level={3}>Rising Associates</Title>
              </Col>
              <Col span={8} offset={8} style={{ alignItems: "flex-end" }}>
                <Button
                  onClick={() => {
                    logout();
                    navigate("/");
                  }}
                  type="primary"
                >
                  Logout
                </Button>
              </Col>
            </Row>
            {/* <CommonHeader isAuthenticated={true} pageContext="dashboard" /> */}
          </Header>
          <Content style={{ padding: 20 }}>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Outlet />
              </div>
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Rising Associates ©2022 Created by LTV Technology
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default Dashboard;
