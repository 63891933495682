import create from 'zustand'
import { STATUS } from '../constants';
import { APIS } from '../services/api';

export const useUserStore = create((set,get) => ({
  addUser: async (payload) => {
    set({ addUserStatus: STATUS.FETCHING })
    const response = await APIS.addUserApi(payload)
    if (response.ok) {
      set({ addUserData: response.data, addUserStatus: STATUS.SUCCESS })
      get().getUsers();
    }
    else {
      set({ addUserStatus: STATUS.FAILED, loginData: null })
    }
  },
  updateUser: async (payload) => {
    set({ updateUserStatus: STATUS.FETCHING })
    const response = await APIS.updateUserApi(payload)
    if (response.ok) {
      set({ updateUserData: response.data, updateUserStatus: STATUS.SUCCESS })
    }
    else {
      set({ updateUserStatus: STATUS.FAILED, loginData: null })
    }
  },
  getUsers: async (payload) => {
    set({ getUsersStatus: STATUS.FETCHING })
    const response = await APIS.getUsersApi(payload)
    if (response.ok) {
      set({ getUserData: response.data?.results, getUsersStatus: STATUS.SUCCESS });
    }
    else {
      set({ getUsersStatus: STATUS.FAILED, loginData: null })
    }
  },
}));