import create from 'zustand'
import { STATUS } from '../constants';
import { APIS } from '../services/api';

export const usePincodeStore = create((set,get) => ({
  addPinCode: async (payload) => {
    set({ addPincodeStatus: STATUS.FETCHING })
    const response = await APIS.addPinCode(payload)
    if (response.ok) {
      set({ addUserData: response.data, addPincodeStatus: STATUS.SUCCESS })
      get().getUsers();
    }
    else {
      set({ addPincodeStatus: STATUS.FAILED, loginData: null })
    }
  },
  getAllPincode: async () => {
    set({ status: STATUS.FETCHING })
    const response = await APIS.getAllPincode()
    if (response.ok) {
      set({ allPincode: response.data, status: STATUS.SUCCESS });
    }
    else {
      set({ status: STATUS.FAILED, loginData: null })
    }
  },
}));