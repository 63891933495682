import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Form, Input, message, Radio, Select } from "antd";
import { useProductStore } from "../../store/productStore";
import { useCategoryStore } from "../../store/categoryStore";
import { map } from "lodash";
import { useBankStore } from "../../store/bankStore";
import UseStatusCheck from "../../lib/UseStatusCheck";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HtmlEditor from "../../components/htmlEditor";
import { useHtmlStore } from "../../store/htmlStore";
import { STATUS } from "../../constants";

const AddProduct = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const [htmlState, setHtmlState] = useState({
    faq: "",
    process: "",
    works: "",
  });
  const [inputs, setInputs] = useState([{product_feature:''}]);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const addProduct = useProductStore((s) => s.addProduct);
  const updateProduct = useProductStore((s) => s.updateProduct);
  const status = useProductStore((s) => s.addProductStatus);
  const updateProductStatus = useProductStore((s) => s.updateProductStatus);

  const { getCategories, allCategories } = useCategoryStore((state) => ({
    getCategories: state.getCategories,
    allCategories: state.allCategories,
  }));

  const { getAllBank, allBanks } = useBankStore((state) => ({
    getAllBank: state.getAllBank,
    allBanks: state.allBanks,
  }));

  const { createHtmlText, htmlText, htmlStatus } = useHtmlStore((state) => ({
    createHtmlText: state.createHtmlText,
    htmlText: state.htmlText,
    htmlStatus: state.htmlStatus,
  }));

  useEffect(() => {
    getCategories();
    getAllBank();
  }, [getAllBank, getCategories]);

  const handleInputChange = (event, index) => {
    const values = [...inputs];
    values[index] = {product_feature: event.target.value};
    setInputs(values);
  };

  const addInput = () => {
    setInputs([...inputs, ""]);
  };
  useEffect(() => {
    if (state) {
      form.setFieldsValue({
        name: state.name,
        description: state.description,
        url: state.url,
        categoryId: state?.product_category_id,
        // bankId: state.bankId,
        minimum_salary: state?.product_criteria_data?.minimum_salary,
        maximum_salary: state?.product_criteria_data?.maximum_salary,
        cibil: state.cibil,
        imageUrl: state?.product_image_data?.imageUrl,
        priority: state.priority,
        is_own: state.is_own,
        is_active: state.is_active,
      });
    }
  }, [state]);
  const success = htmlStatus === STATUS.SUCCESS;

  const onSubmit = (values) => {
    const data = {
      name: values.name,
      description: values.description,
      url: values.url,
      product_category_id: values.categoryId,
      bank_id: values.bankId,
      is_active: values.is_active,
      is_own: values.is_own,
      priority: values.priority,
      product_criteria_data: {
        minimum_salary: values.minimum_salary,
        maximum_salary: values.maximum_salary,
        cibil: values.cibil,
      },
      product_feature_data: inputs,
      product_image_data: {
        url: values.imageUrl,
      },
    };
    if (htmlState.faq || htmlState.process || htmlState.works) {
      createHtmlText(htmlState);
      if (success) {
        console.log('success');
        _handleSubmit(data);
      }
    } else {
      if (state) {
        updateProduct({ ...data, id: state.id });
      } else {
        console.log('add product');
        addProduct(data);
      }
    }
    form.resetFields();
  };

  const _handleSubmit = useCallback(
    (data) => {
      if (state) {
        updateProduct({ ...data, id: state.id, meta_data_json: htmlText });
      } else {
        addProduct({ ...data, meta_data_json: htmlText });
      }
    },
    [htmlStatus]
  );

  UseStatusCheck({
    status,
    onSuccess: () => {
      messageApi.open({
        type: "success",
        content: "Product Added Successfully",
      });
      navigate("/product/list");
      form.resetFields();
    },
    onError: () => {
      messageApi.open({
        type: "error",
        content: "Failed",
      });
    },
  });

  UseStatusCheck({
    updateProductStatus,
    onSuccess: () => {},
  });

  console.log({inputs});

  return (
    <>
      {contextHolder}
      <Card title="Add Product">
        <Form
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 14,
          }}
          form={form}
          onFinish={onSubmit}
          name="register"
          scrollToFirstError
          style={{ margin: "20px" }}
        >
          <Form.Item
            name="categoryId"
            label="Select Category"
            rules={[
              {
                required: true,
                message: "Please select product Category",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a Category"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={map(allCategories, (category) => ({
                label: category.name.toString(),
                value: category.id,
              }))}
            />
          </Form.Item>
          <Form.Item name="bankId" label="Select Bank">
            <Select
              showSearch
              placeholder="Select a Bank"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={map(allBanks, (bank) => ({
                label: bank.name.toString(),
                value: bank.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            tooltip="Enter Product Name"
            rules={[
              {
                required: true,
                message: "Please enter Product Name!",
                whitespace: true,
                type: "string",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="imageUrl"
            label="Image Url"
            tooltip="Enter Product Image URL"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="description"
            tooltip="Enter Product description"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="url"
            label="Url"
            tooltip="Enter Url"
            rules={[
              {
                required: true,
                message: "Please enter Product Url!",
                whitespace: true,
                type: "string",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="priority"
            label="Priority"
            tooltip="Product priority"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="minimum_salary" label="Minimum Salary">
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="maximum_salary" label="Maximum Salary">
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="cibil" label="Cibil score">
            <Input />
          </Form.Item>
          {/* will uncomment later */}
          {/* <Form.Item name="pincode" label="Pincode">
          <Input />
        </Form.Item>
        <Form.Item name="company" label="Company">
          <Input />
        </Form.Item> */}
          <Form.Item
            label="Status"
            name="is_active"
            rules={[
              {
                required: true,
                message: "Please select status!",
                type: "string",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="1">Active</Radio.Button>
              <Radio.Button value="0">Inactive</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Is own"
            name="is_own"
            rules={[
              {
                required: true,
                message: "Please select status!",
                type: "string",
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value="1">Yes</Radio.Button>
              <Radio.Button value="0">No</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Product Feature">
            {inputs.map((input, index) => (
              <Input
                key={index}
                style={{ width: "100%", marginBottom: 10 }}
                onChange={(event) => handleInputChange(event, index)}
              />
            ))}
            <Button onClick={addInput} color={"#E1E6E9"}>
              <PlusOutlined size={45} />
            </Button>
          </Form.Item>
          <Form.Item label="FAQ">
            <HtmlEditor
              setHtmlDataState={(e) => setHtmlState({ ...htmlState, faq: e })}
            />
          </Form.Item>
          <Form.Item label="How to Process">
            <HtmlEditor
              setHtmlDataState={(e) =>
                setHtmlState({ ...htmlState, process: e })
              }
            />
          </Form.Item>
          <Form.Item label="How it Works">
            <HtmlEditor
              setHtmlDataState={(e) => setHtmlState({ ...htmlState, works: e })}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AddProduct;
