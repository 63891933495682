export const STATUS = {
    FETCHING: "FETCHING",
    SUCCESS:'SUCCESS',
    FAILED: 'FAILED',
    NOT_STARTED: "NOT_STARTED"
  }
  
  export const LOCALSTORAGE_KEYS = {
    LOGIN_TOKEN: '@login-token',
    REFRESH_TOKEN: '@refresh-token',
    USER: '@user'
  }

  export const COOKIE_NAME = 'rising-admin'
  