import "./App.css";
import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./css/custom.css";
import "./css/main.bundle.css";
import { Login } from "./routes/login";
import { AppContext } from "./contexts/AppContext";
import { DashboardRoutes } from "./routes/Routes/dashboardRoutes";
import { AppProvider } from "./contexts/AppProvider";
import { CookiesProvider } from "react-cookie";

function App() {
  return (
    <AppProvider>
      <CookiesProvider>
        <AppRouter />
      </CookiesProvider>
    </AppProvider>
  );
}

export const AppRouter = () => {
  const { isLogin } = useContext(AppContext);
  return (
    <BrowserRouter>
      {isLogin ? (
        <DashboardRoutes />
      ) : (
        <Routes>
          <Route path={"/"} element={<Login />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default App;
