import React, { useEffect } from "react";
import { Button, Form, Input, Select } from "antd";
import { usePincodeStore } from "../../store/pincodeStore";
import { map } from "lodash";

const AddCompanny = ({ onSubmit, editData, setIsModalOpen }) => {
  const [form] = Form.useForm();
  const getAllPincode = usePincodeStore((s) => s.getAllPincode);
  const allPincode = usePincodeStore((s) => s.allPincode);
  console.log("getAllPincode", allPincode);
  
  // get pincode list
  useEffect(() => {
    getAllPincode();
  }, [getAllPincode]);

  useEffect(() => {
    if (editData) {
    }
  }, [editData, form]);

  const _updateCompany = (values) => {
    console.log({ values });
    setIsModalOpen(false);
  };

  return (
    <Form
      labelCol={{
        span: 7,
      }}
      form={form}
      onFinish={editData ? _updateCompany : onSubmit}
      name="register"
      scrollToFirstError
      style={{ margin: "20px", padding: "20px" }}
    >
      <Form.Item
        name="name"
        label="Name"
        tooltip="Enter Company Name"
        rules={[
          {
            required: true,
            message: "Please enter your company Name!",
            whitespace: true,
            type: "string",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="address"
        label="Address"
        tooltip="Enter Company Address"
        rules={[
          {
            required: true,
            message: "Please enter your company Address!",
            whitespace: true,
            type: "string",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="gst_no"
        label="GST No."
        tooltip="Enter Company GST No."
        // rules={[
        //   {
        //     required: true,
        //     message: "Please enter company GST No.!",
        //   },
        // ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="pincode_id"
        label="PIN CODE."
        // rules={[
        //   {
        //     required: true,
        //     message: "Please enter company PIN CODE!",
        //   },
        // ]}
      >
        <Select
          showSearch
          placeholder="Select a PINCODE"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={map(allPincode, pincode => ({label: pincode.pincode.toString(), value: pincode.id}))}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddCompanny;
