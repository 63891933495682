import create from "zustand";
import { STATUS } from "../constants";
import { APIS } from "../services/api";

export const useRoleStore = create((set, get) => ({
  addRole: async (payload) => {
    set({ addStatus: STATUS.FETCHING });
    const response = await APIS.addRole(payload);
    if (response.ok) {
      set({addStatus: STATUS.SUCCESS });
      get().getAllRole();
    } else {
      set({ addStatus: STATUS.FAILED });
    }
  },
  updateRole: async (payload) => {
    set({ updateStatus: STATUS.FETCHING });
    const response = await APIS.updateRole(payload);
    if (response.ok) {
      set({ updateStatus: STATUS.SUCCESS });
      get().getAllRole();
    } else {
      set({ updateStatus: STATUS.FAILED });
    }
  },
  getAllRole: async () => {
    set({ status: STATUS.FETCHING });
    const response = await APIS.getAllRole();
    if (response.ok) {
      set({ allRoles: response.data, status: STATUS.SUCCESS });
    } else {
      set({ status: STATUS.FAILED });
    }
  },
}));
