import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Modal,
  Popconfirm,
  Popover,
  Table,
  Typography,
} from "antd";
import _ from "lodash";
import AddImage from "./Add";
import { useSliderImageStore } from "../../store/sliderStore";

const ImageSlider = ({}) => {
  const {
    gerSliderImages,
    sliderImagesData,
    addSliderImage,
    updateSliderImage,
  } = useSliderImageStore((state) => ({
    gerSliderImages: state.gerSliderImages,
    sliderImagesData: state.sliderImagesData,
    addSliderImage: state.addSliderImage,
    updateSliderImage: state.updateSliderImage,
  }));
  const [data, setData] = useState();
  const [editData, setEditData] = useState();
  const [deleted, setDeleted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    gerSliderImages();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const _handleSubmit = (values) => {
    const formData = FormData()
    formData.append('file', values)
    addSliderImage(formData);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const edit = (record) => {
    setIsModalOpen(true);
    setEditData(record);
  };

  const _onDelete = (record) => {
    setData(_.filter(data, (d) => d.key !== record.key));
  };
  const hide = () => {
    setDeleted(false);
  };
  const handleOpenChange = (record) => {
    setDeleted(record);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Priority",
      dataIndex: "prioriry",
    },
    {
      title: "URL",
      dataIndex: "url",
    },
    {
      title: "Status",
      dataIndex: "is_active",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <>
            <Typography.Link
              onClick={() => edit(record)}
              style={{ marginRight: "30px " }}
            >
              Edit
            </Typography.Link>
            <Popover
              content={
                <span>
                  <Typography.Link
                    onClick={() => _onDelete(record)}
                    style={{ marginRight: "8px" }}
                  >
                    Save
                  </Typography.Link>
                  <Popconfirm title="Sure to cancel?" onConfirm={hide}>
                    <a>Cancel</a>
                  </Popconfirm>
                </span>
              }
              title="Are you want to delete?"
              trigger="click"
              open={deleted}
              onOpenChange={() => handleOpenChange(record)}
            >
              <Typography.Link onClick={() => setDeleted(record)}>
                Delete
              </Typography.Link>
            </Popover>
          </>
        );
      },
    },
  ];

  const onChange = (pagination, sorter, extra) => {
    console.log("params", pagination, sorter, extra);
  };

  return (
    <Card
      title="Slider"
      extra={
        <Button type="primary" onClick={showModal}>
          Add Slider Image
        </Button>
      }
    >
      <Modal
        title="Slider Image"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <AddImage
          onSubmit={_handleSubmit}
          editData={editData}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
      <Table
        bordered
        columns={columns}
        dataSource={sliderImagesData}
        onChange={onChange}
      />
    </Card>
  );
};

export default ImageSlider;
