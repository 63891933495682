
import create from "zustand";
import { STATUS } from "../constants";
import { APIS } from "../services/api";

export const useSliderImageStore = create((set, get) => ({
  addSliderImage: async (payload) => {
    set({ addSliderImageStatus: STATUS.FETCHING });
    const response = await APIS.sliderImage(payload);
    if (response.ok) {
      set({sliderImageData:response.data,addSliderImageStatus: STATUS.SUCCESS });
    } else {
      set({ addSliderImageStatus: STATUS.FAILED });
    }
  },
  updateSliderImage: async (payload) => {
    set({ updateSliderImageStatus: STATUS.FETCHING });
    const response = await APIS.updateSliderImage(payload);
    if (response.ok) {
      set({updateImagesData:response.data, updateSliderImageStatus: STATUS.SUCCESS });
    } else {
      set({ updateSliderImageStatus: STATUS.FAILED });
    }
  },
  gerSliderImages: async () => {
    set({ sliderOmagesStatus: STATUS.FETCHING });
    const response = await APIS.getSliderImages();
    if (response.ok) {
      set({ sliderImagesData: response.data, sliderImagesStatus: STATUS.SUCCESS });
    } else {
      set({ sliderOmagesStatus: STATUS.FAILED });
    }
  },
}));
