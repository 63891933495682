import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  Popconfirm,
  Popover,
  Table,
  Typography,
} from "antd";
import _ from "lodash";
import AddBank from "./Add";
import { useBankStore } from "../../store/bankStore";

const BankList = () => {
  const [form] = Form.useForm();
  const addBank = useBankStore((state) => state.addBank);
  const { getAllBank, allBanks } = useBankStore((state) => ({
    getAllBank: state.getAllBank,
    allBanks: state.allBanks,
  }));
  const [data, setData] = useState();
  const [editData, setEditData] = useState();
  const [deleted, setDeleted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getAllBank();
  }, []);
  console.log({ allBanks });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const _handleSubmit = (values) => {
    addBank(values);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const edit = (record) => {
    setIsModalOpen(true);
    setEditData(record);
  };

  const _onDelete = (record) => {
    setData(_.filter(data, (d) => d.key !== record.key));
  };
  const hide = () => {
    setDeleted(false);
  };
  const handleOpenChange = (record) => {
    setDeleted(record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      // sorter: (a, b) => a.name.length - b.name.length,
      // sortDirections: ["descend"],
      // render: (text, record) => (
      //   <span>
      //     {record.first_name} {record.last_name}
      //   </span>
      // ),
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <>
            <Typography.Link
              onClick={() => edit(record)}
              style={{ marginRight: "30px " }}
            >
              Edit
            </Typography.Link>
            <Popover
              content={
                <span>
                  <Typography.Link
                    onClick={() => _onDelete(record)}
                    style={{ marginRight: "8px" }}
                  >
                    Save
                  </Typography.Link>
                  <Popconfirm title="Sure to cancel?" onConfirm={hide}>
                    <a>Cancel</a>
                  </Popconfirm>
                </span>
              }
              title="Are you want to delete?"
              trigger="click"
              open={deleted}
              onOpenChange={() => handleOpenChange(record)}
            >
              <Typography.Link onClick={() => setDeleted(record)}>
                Delete
              </Typography.Link>
            </Popover>
          </>
        );
      },
    },
  ];

  const onChange = (pagination, sorter, extra) => {
    console.log("params", pagination, sorter, extra);
  };

  return (
    <Card
      title="List Bank"
      extra={
        <Button type="primary" onClick={showModal}>
          Add New Bank
        </Button>
      }
    >
      <Modal
        title="Add Bank"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <AddBank
          onSubmit={_handleSubmit}
          editData={editData}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
      <Table
        bordered
        columns={columns}
        dataSource={allBanks}
        onChange={onChange}
      />
    </Card>
  );
};

export default BankList;
