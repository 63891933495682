import { Card, Col, Row } from "antd";
import React from "react";
import { useEffect } from "react";
import { useBankStore } from "../../store/bankStore";
import { useProductStore } from "../../store/productStore";
import { useUserStore } from "../../store/userStore";
import { useVendorStore } from "../../store/vendorStore";

export default function DashboardData() {

    const { getAllBank, allBanks } = useBankStore()
    const { getVendors, vendorList } = useVendorStore()
    const { getUsers, getUserData } = useUserStore()
    const { getProducts, getProductData } = useProductStore()

    useEffect(() => {
        getAllBank()
        getVendors()
        getUsers()
        getProducts()
    }, [getAllBank, getProducts, getUsers, getVendors])

    return (
        <div>
            <Card
                title="Dashboard"
            >
                <Row style={{ justifyContent: "space-evenly", flexWrap: "wrap" }}>
                    <Col style={{ margin: 14, backgroundColor: "#EBF5FB", padding: 14, borderRadius: 14, boxShadow: "revert" }} md={7} sm={24} xs={24}>
                        <Row style={{ fontSize: 18, fontWeight: "bold" }}>Today Leads</Row>
                        <Row>30</Row>
                    </Col>
                    <Col style={{ margin: 14, backgroundColor: "#EBF5FB", padding: 14, borderRadius: 14, boxShadow: "revert" }} md={7} sm={24} xs={24}>
                        <Row style={{ fontSize: 18, fontWeight: "bold" }}>Total Leads</Row>
                        <Row>{getUserData?.length || 0}</Row>
                    </Col>
                    <Col style={{ margin: 14, backgroundColor: "#EBF5FB", padding: 14, borderRadius: 14, boxShadow: "revert" }} md={7} sm={24} xs={24}>
                        <Row style={{ fontSize: 18, fontWeight: "bold" }}>Total Venders</Row>
                        <Row>{vendorList?.length || 0}</Row>
                    </Col>
                    <Col style={{ margin: 14, backgroundColor: "#EBF5FB", padding: 14, borderRadius: 14, boxShadow: "revert" }} md={7} sm={24} xs={24}>
                        <Row style={{ fontSize: 18, fontWeight: "bold" }}>Bank Accounts</Row>
                        <Row>{allBanks?.length || 0}</Row>
                    </Col>
                    <Col style={{ margin: 14, backgroundColor: "#EBF5FB", padding: 14, borderRadius: 14, boxShadow: "revert" }} md={7} sm={24} xs={24}>
                        <Row style={{ fontSize: 18, fontWeight: "bold" }}>Total Products</Row>
                        <Row>{getProductData?.total || 0}</Row>
                    </Col>
                    <Col style={{ margin: 14, backgroundColor: "#EBF5FB", padding: 14, borderRadius: 14, boxShadow: "revert" }} md={7} sm={24} xs={24}>
                        <Row style={{ fontSize: 18, fontWeight: "bold" }}>Total Lead</Row>
                        <Row>30</Row>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}