export const ROUTES = {
    LOGIN: '/login',
    DASHBOARD: 'dashboard',
    ADD_VENDORS:'vendor/add',
    LIST_VENDORS:'vendor/list',
    USERS:'user',
    ADD_PRODUCT:'product/add',
    LIST_PRODUCT:'product/list',
    PRODUCT_DETAILS:'product/details',
    CATEGORY:'product/category',
    LIST_USERS:'user/list',
    COMPANY: 'company',
    BANK: 'bank',
    ROLE: 'role',
    SLIDER: 'slider',
}