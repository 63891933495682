import { useEffect, useMemo, useState } from "react";
import { LOCALSTORAGE_KEYS } from "../constants";
import { APIS } from "../services/api";
import { useLoginStore } from "../store/loginStore";
import { AppContext } from "./AppContext";

export const AppProvider = ({ children }) => {
  const token = localStorage.getItem(LOCALSTORAGE_KEYS.LOGIN_TOKEN);
  const refresh = localStorage.getItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN);
  const savedUser = localStorage.getItem(LOCALSTORAGE_KEYS.USER);
  console.log("cookies", localStorage, token, savedUser);
  const userProfile = useLoginStore((state) => state.userProfile);
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState();
  const _logout = () => {
    localStorage.removeItem(LOCALSTORAGE_KEYS.LOGIN_TOKEN);
    localStorage.removeItem(LOCALSTORAGE_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(LOCALSTORAGE_KEYS.USER);
    setIsLogin(false);
  };

  useEffect(() => {
    if (token) {
      setIsLogin(true);
      setUser(undefined);
      setUser(JSON.parse(savedUser));
      userProfile({ rtoken: token });
      APIS.setAuthorizationHeader(token);
    } else {
      setIsLogin(false);
    }
  }, [savedUser, token, userProfile]);

  const appContextValue = useMemo(
    () => ({
      isLogin,
      setIsLogin,
      user,
      setUser,
      logout: _logout,
    }),
    [isLogin, user]
  );
  return (
    <AppContext.Provider value={appContextValue}>
      {children}
    </AppContext.Provider>
  );
};
