import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useUserStore } from "../../store/userStore";

const AddUser = ({onSubmit, editData, setIsModalOpen}) => {
  const [form] = Form.useForm();
  const updateUser = useUserStore(s => s.updateUser)

  useEffect(() => {
    if(editData){
      form.setFieldsValue({
      first_name: editData.first_name,
      last_name:editData.last_name,
      email: editData.email,
      mobile_no: editData.mobile_no,
      isActive: true,
      role_master_id: '6Y28bqlMeln4E9oM', // for role call center
    });
    }
  },[editData, form])

  const _updateUser = (values) => {
    console.log({values});
    updateUser({
      id:editData.id,
      ...values
    })
    setIsModalOpen(false)
  }

  return (
    <Form
    labelCol={{
      span: 7,
    }}
    form={form}
    onFinish={editData?_updateUser: onSubmit}
    name="register"
    scrollToFirstError
    style={{ margin: '20px',padding:'20px' }}
  >
    <Form.Item
      name="first_name"
      label="First Name"
      tooltip="Enter your Name" 
      rules={[
        {
          required: true,
          message: "Please enter First Name!",
          whitespace: true,
          type: "string",
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="last_name"
      label="Last Name"
      tooltip="Enter your Name"
      rules={[
        {
          required: true,
          message: "Please enter your Last Name!",
          whitespace: true,
          type: "string",
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="mobile_no"
      label="Phone Number"
      rules={[
        {
          required: true,
          message: "Please input your phone number!",
          max: 10,
        },
        // {
        //   type: "number",
        //   message: "Please Enter Valid Phone number",
        //   pattern: /[6-9]{1}[0-9]{9}/,
        // },
      ]}
    >
      <Input style={{ width: "100%" }}  />
    </Form.Item>
    <Form.Item
      name="email"
      label="E-mail"
      rules={[
        {
          type: "email",
          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "The input is not valid E-mail!",
        },
        {
          required: true,
          message: "Please input your E-mail!",
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
  );
};

export default AddUser;
