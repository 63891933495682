import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Table,
  Typography,
} from "antd";
import _ from "lodash";
import { useCategoryStore } from "../../store/categoryStore";

const Category = ({}) => {
  const [form] = Form.useForm();
  const addCategory = useCategoryStore((state) => state.addCategory);
  const updateCategory = useCategoryStore((state) => state.updateCategory);
  const { getCategories, allCategories } = useCategoryStore((state) => ({
    getCategories: state.getCategories,
    allCategories: state.allCategories,
  }));
  const [data, setData] = useState();
  const [editData, setEditData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const _handleSubmit = (values) => {
    addCategory(values);
    form.resetFields()
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const edit = (record) => {
    console.log({record});
    setIsModalOpen(true);
    setEditData(record);
  };
  

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend"],
    },
    
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <>
            <Typography.Link
              onClick={() => edit(record)}
              style={{ marginRight: "30px " }}
            >
              Edit
            </Typography.Link>
          </>
        );
      },
    },
  ];

  const onChange = (pagination, sorter, extra) => {
    console.log("params", pagination, sorter, extra);
  };

  return (
    <Card
      title="List Categories"
      extra={
        <Button type="primary" onClick={showModal}>
          Add New Category
        </Button>
      }
    >
      <Modal
        title="Add Category"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[]}
      >
        <AddCategory
          onSubmit={_handleSubmit}
          editData={editData}
          setIsModalOpen={setIsModalOpen}
          updateCategory={updateCategory}
        />
      </Modal>
      <Table
        bordered
        columns={columns}
        dataSource={allCategories}
        onChange={onChange}
      />
    </Card>
  );
};


const AddCategory = ({onSubmit, editData, updateCategory,setIsModalOpen}) => {
    const [form] = Form.useForm();
    console.log({form});

  useEffect(() => {
    if(editData){
      form.setFieldsValue({
      name: editData.name
    });
    }
  },[editData, form])

  const _updateCategory = (values) => {
    console.log({values});
    updateCategory({
      id:editData.id,
      ...values
    })
    form.resetFields()
    setIsModalOpen(false)
  }
    return(
        <Form
        labelCol={{
          span: 9,
        }}
        form={form}
        onFinish={editData? _updateCategory: onSubmit}
        name="register"
        scrollToFirstError
        style={{ margin: '20px' }}
      >
        <Form.Item
          name="name"
          label="Category Name"
          tooltip="Enter Category Name" 
          rules={[
            {
              required: true,
              message: "Please enter Category Name!",
              whitespace: true,
              type: "string",
            },
          ]}
        >
          <Input />
        </Form.Item>
       
        <Form.Item style={{alignSelf:'center'}}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
}
 export default Category;
