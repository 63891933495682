import React, { useEffect, useState } from "react";
import { Button, Card, Drawer, Space, Table, Typography } from "antd";
import _, { find } from "lodash";
import Add from "./AddVendor";
import { useVendorStore } from "../../store/vendorStore";
import { useRoleStore } from "../../store/roleStore";

const VendorList = ({}) => {
  const addVendor = useVendorStore((state) => state.addVendor);
  const { getVendors, vendorList } = useVendorStore((state) => ({
    getVendors: state.getVendors,
    vendorList: state.vendorList,
  }));
  const [data, setData] = useState();
  const [editData, setEditData] = useState();
  const [deleted, setDeleted] = useState(false);
  const [open, setOpen] = useState(false);
  const { getAllRole, allRoles } = useRoleStore((state) => ({
    getAllRole: state.getAllRole,
    allRoles: state.allRoles,
  }));
  
  useEffect(() => {
    getAllRole();
  }, [getAllRole]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getVendors();
  }, [getVendors]);
  console.log({ vendorList });

  const _handleSubmit = (values) => {
    const data = {
      name: values.vendor_name,
      call_center_name: values.vendor_name,
      is_active: true,
      inactive_reason: null,
      admin_id: null,
      user_master_data: {
        first_name: values.name,
        last_name: "",
        email: values.email,
        mobile_no: values.phone,
        is_active: true,
        meta_data_json: {},
        role_master_id: find(allRoles, (role) => role.code === "VENDOR").id,
      },
    };
    addVendor(data);
    setOpen(false);
  };

  const edit = (record) => {
    setOpen(true);
    setEditData(record);
  };

  const _onDelete = (record) => {
    setData(_.filter(data, (d) => d.key !== record.key));
  };
  const hide = () => {
    setDeleted(false);
  };
  const handleOpenChange = (record) => {
    setDeleted(record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Website Code",
      dataIndex: "uniqueCode",
    },
    {
      title: "Contact",
      dataIndex: "mobile_no",
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <>
            <Typography.Link
              onClick={() => edit(record)}
              style={{ marginRight: "30px " }}
            >
              Edit
            </Typography.Link>
            {/* <Popover
              content={
                <span>
                  <Typography.Link
                    onClick={() => _onDelete(record)}
                    style={{ marginRight: "8px" }}
                  >
                    Save
                  </Typography.Link>
                  <Popconfirm title="Sure to cancel?" onConfirm={hide}>
                    <a>Cancel</a>
                  </Popconfirm>
                </span>
              }
              title="Are you want to delete?"
              trigger="click"
              open={deleted}
              onOpenChange={() => handleOpenChange(record)}
            >
              <Typography.Link onClick={() => setDeleted(record)}>
                Delete
              </Typography.Link>
            </Popover> */}
          </>
        );
      },
    },
  ];

  const onChange = (pagination, sorter, extra) => {
    console.log("params", pagination, sorter, extra);
  };

  return (
    <Card
      title="List Vendor"
      extra={
        <Button type="primary" onClick={showDrawer}>
          Add New Vendor
        </Button>
      }
    >
      <Drawer
        title="Create a new Vendor"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Add
          editData={editData}
          setIsModalOpen={setOpen}
          onSubmit={_handleSubmit}
        />
      </Drawer>
      <Table
        bordered
        columns={columns}
        dataSource={vendorList?.map((vendor) => ({
          ...vendor,
          ...vendor.userMaster,
        }))}
        onChange={onChange}
      />
    </Card>
  );
};

export default VendorList;
