import { Button, Checkbox, Form, Input } from "antd";
import { useContext } from "react";
import { STATUS } from "../../constants";
import { AppContext } from "../../contexts/AppContext";
import UseStatusCheck from "../../lib/UseStatusCheck";
import { useLoginStore } from "../../store/loginStore";

export const Login = () => {
  const { setIsLogin } = useContext(AppContext);

  const loginUser = useLoginStore((state) => state.loginUser);
  const loginStatus = useLoginStore((state) => state.loginStatus);
  const loading = loginStatus === STATUS.FETCHING;
  const _onSubmit = (values) => {
    if (values.username && values.password) {
      loginUser({ username: values.username, password: values.password });
    }
  };

  UseStatusCheck({
    status: loginStatus,
    onSuccess: () => {
      setIsLogin(true);
    },
    onError: () => {

    }
  })

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-page">
      <div className="login-box">
        <div className="illustration-wrapper">
          <img
            src="https://mixkit.imgix.net/art/preview/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.png?q=80&auto=format%2Ccompress&h=700"
            alt="Login"
          />
        </div>
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={_onSubmit}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title">Welcome back</p>
          <p>Login to the Dashboard</p>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="login-form-button"
              onClick={(e) => _onSubmit(e)}
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
