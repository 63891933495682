import React from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import Dashboard from "../dashboard";
import User from "../user"
import AddVendor from "../vendor/AddVendor";
import ListVendors from "../vendor/ListVendors";
import Company from "../company";
import Bank from "../bank";
import Role from "../role";
import AddProduct from "../product/AddProduct";
import ProductList from "../product";
import Category from "../product/category";
import ViewDetails from "../product/ViewDetails";
import ImageSlider from "../imageSlider";
import DashboardData from "../dashboard/dashboard";

export const DashboardRoutes = (props) => {
  return (
    <Routes>
      <Route path={"/"} element={<Dashboard />}>
        <Route path={ROUTES.DASHBOARD} element={<DashboardData />} />
        <Route path={ROUTES.ADD_VENDORS} element={<AddVendor />} />
        <Route path={ROUTES.LIST_VENDORS} element={<ListVendors />} />
        <Route path={ROUTES.USERS} element={<User/>} />
        <Route path={ROUTES.LIST_PRODUCT} element={<ProductList/>} />
        <Route path={ROUTES.ADD_PRODUCT} element={<AddProduct/>} />
        <Route path={ROUTES.PRODUCT_DETAILS} element={<ViewDetails/>} />
        <Route path={ROUTES.COMPANY} element={<Company />} />
        <Route path={ROUTES.BANK} element={<Bank/>} />
        <Route path={ROUTES.ROLE} element={<Role />} />
        <Route path={ROUTES.CATEGORY} element={<Category />} />
        <Route path={ROUTES.SLIDER} element={<ImageSlider />} />
      </Route>
    </Routes>
  );
};
