import create from "zustand";
import { STATUS } from "../constants";
import { APIS } from "../services/api";

export const useBankStore = create((set, get) => ({
  addBank: async (payload) => {
    set({ addStatus: STATUS.FETCHING });
    const response = await APIS.addBank(payload);
    if (response.ok) {
      set({ addCompanyData: response.data, addStatus: STATUS.SUCCESS });
      get().getAllBank();
    } else {
      set({ addStatus: STATUS.FAILED });
    }
  },
  updateBank: async (payload) => {
    set({ updateStatus: STATUS.FETCHING });
    const response = await APIS.updateBank(payload);
    if (response.ok) {
      set({ updateStatus: STATUS.SUCCESS });
      get().getAllBank();
    } else {
      set({ updateStatus: STATUS.FAILED });
    }
  },
  getAllBank: async () => {
    set({ status: STATUS.FETCHING });
    const response = await APIS.getAllBank();
    if (response.ok) {
      set({ allBanks: response.data, status: STATUS.SUCCESS });
    } else {
      set({ status: STATUS.FAILED });
    }
  },
}));
